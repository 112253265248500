<template>
  <Overview
        :moduleName="$tc(navName, 1)"
        :moduleNamePlural="$tc(navName, 2)"
        sort="name"
        :newButtonName="$t('form.add', [$t(navName)])"
        :selectFields=selectFields
        :filterOptions="filterOptions"
        :itemService=itemService
        :itemFormRef=itemFormRef
        ref="overview"
    >
        <template #columns>
            <Column field="name" :header="$t('overview.name')" :sortable="true">
              <template #body="slotProps">
                <div class="uploaded-logo uploaded-logo-sm" :style="{'background-image': `url(${slotProps.data.icon_url || (slotProps.data.franchise ? slotProps.data.franchise.icon_url : false)})`}"></div>
                {{fullLocationName(slotProps.data)}}
              </template>
           </Column>
            <Column field="city" :header="$t('location.place')" :sortable="true" style="width: 160px"></Column>
            <Column field="debtor_number" :header="$t('location.debtornr')" :sortable="true" style="width: 150px"></Column>
            <Column field="dealer.name" :header="$t('navigation.dealers')" :sortable="true" style="width: 200px"></Column>
            <Column field="source_setting.version_number" :header="$t('overview.version')" :sortable="true" style="width: 100px">
              <template #body="slotProps">
                <span v-if="slotProps.data.source_setting.version_number" data-bs-toggle="tooltip" :data-bs-title="slotProps.data.source_setting.version_number">{{slotProps.data.source_setting.version_number.split(" ")[0]}}</span>
              </template>
            </Column>
            <Column field="source_autosync" :header="$t('location.sync')" :sortable="true" style="width: 75px">
              <template #body="slotProps">
                  <div class="d-flex justify-content-between">
                    <i :class="{'fa-regular fa-rotate-right text-success': true, 'opacity-0': !slotProps.data.source_autosync}" :data-bs-toggle="slotProps.data.source_autosync ? 'tooltip' : ''" :data-bs-title="`Automatisch importeren`"></i>
                    <i :class="{'fa-regular fa-rotate-left text-success': true, 'opacity-0': !slotProps.data.bookkeeping_autosync}" :data-bs-toggle="slotProps.data.bookkeeping_autosync ? 'tooltip' : ''" :data-bs-title="`Automatisch exporteren`"></i>
                  </div>
              </template>
            </Column>
            <Column field="name" :header="$t('overview.links')" :sortable="false">
              <template #body="slotProps">
                <div class="d-flex gap-2 align-items-center">
                  <div class="pill text-light"
                    :class="getStatus(slotProps.data.source_setting)"
                    :data-bs-toggle="slotProps.data.source_setting.status_message ? `tooltip` : null" :data-bs-title="slotProps.data.source_setting.status_message"
                    >
                      unTill
                  </div>
                  <div v-if="slotProps.data.bookkeeping_system_location && slotProps.data.bookkeeping_system_location.bookkeeping_system_auth" 
                    class="pill text-light" :class="getStatus(slotProps.data.bookkeeping_system_location)"
                    >
                      {{slotProps.data.bookkeeping_system_location.bookkeeping_system_auth.bookkeeping_system.name}}
                  </div>
                  <div v-if="slotProps.data.reservation_system_location"
                    class="pill bg-unidark text-light" :class="getStatus(slotProps.data.reservation_system_location)"
                    :data-bs-toggle="slotProps.data.reservation_system_location.status_message ? `tooltip` : null" :data-bs-title="slotProps.data.reservation_system_location.status_message"
                    >
                      {{slotProps.data.reservation_system_location.reservation_system.name}}
                  </div>
                </div>
              </template>
            </Column>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import bookService from '@/services/BookkeepingService'
import csService from '@/services/CustomerService'
import dealService from '@/services/DealerService'
import franService from '@/services/FranchiseService'
import resService from '@/services/ReservationSystemService'
import datastore from '@/store/data'

export default {
    components: {
        Overview,
        Column
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$refs.overview.getIndex(from);
        })
    },
    data() {
        return {
            item: {},
            itemService: csService,
            itemFormRef: null,
            companyTypes: [],
            selectFields: [],
            navName: 'navigation.locations',
            filterOptions: [
                {
                    field: 'franchise.id',
                    service: franService,
                    name: this.$t('navigation.franchises'),
                    multiple: true
                },
                {
                    field: 'dealer.id',
                    service: dealService,
                    name: this.$t('navigation.dealers'),
                    multiple: false
                },
                {
                    field: 'bookkeepingSystemLocation.bookkeepingSystemAuth.bookkeeping_system_id',
                    service: bookService,
                    name: this.$t('locationtabs.step_5'),
                    multiple: true
                },
                {
                    field: 'reservationSystemLocation.reservationSystem.id',
                    service: resService,
                    name: this.$t('locationtabs.step_6'),
                    multiple: true
                }
            ],
        }
    },
    mounted() {
      //Not customers allowed if they don't have access to at least two locations
      if(datastore.getters.getLocationsToShow != 'all'){
        this.$router.push({name: 'Home'});
      }
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      // const sessionState = urlParams.get('session_state');
      if (code) {
        this.handleCallback(code, state, window.location.pathname)
      }
    },
    methods: {
        getStatus(data){
          const statuses = {"active": "bg-unidark", "error": "bg-danger", "pending": "bg-secondary", "inactive": "bg-secondary"};
          return statuses[data.status] || 'bg-unidark';
        }
    }

}
</script>
